@import "@styles/settings";

.box {
    position: relative;
    display: inline-flex;
}

.button {
    width: 36px;
    height: 36px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        opacity: 0.7;
    }
}

.nav {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    top: 100%;
    left: -2px;
    margin-top: 15px;
    width: 377px;
    background: #fff;
    box-shadow: 0 4px 9px rgba(#000, 0.15);
    padding: 24px;
    z-index: 100;
    &.isOpen {
        visibility: visible;
        opacity: 1;
        margin-top: 10px;
    }
    &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 5px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 15px solid #fff;
    }

    ul {
        list-style: none;
    }
    li {
        border-top: 1px solid #f5f5f5;
        &:first-child {
            border-top: none;
        }
    }
    a {
        text-decoration: none;
        display: block;
        padding: 15px 0;
        transition: 0.3s;
        &:hover {
            opacity: 0.7;
        }
    }
}

@media (max-width: $mobileMaxWidth) {
    .box {
        position: static;
    }
    .nav,
    .overlay {
        position: fixed;
        top: 70px;
        left: 0;
        width: 100%;
    }
    .nav {
        margin: 0 !important;
        overflow-y: auto;
        padding: 18px;
        z-index: 101;
        max-height: calc(100vh - 70px);
        &::before {
            content: none;
        }
    }
    .overlay {
        height: calc(100vh - 70px);
        background-color: rgba(#000, 0.5);
        transition: .3s;
        z-index: 100;
    }
}
