@import "@styles/settings";

.box {
    max-width: 555px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;
}

.title {
    font-size: 44px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 25px;
    br {
        display: none;
    }
}

.description {
    text-align: center;
    font-size: 24px;
    margin-top: 30px;
}

.sum {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 5px;
    font-size: 22px;
    border-bottom: 1px solid #CECECE;
    margin-top: 50px;
    margin-bottom: 80px;

    .key {
        font-size: 28px;
    }
}

.sumMobileText {
    display: none;
}

.option {
    margin-top: 60px;
}

.name {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 15px;
    br {
        display: none;
    }
}

.setting {
    border: 2px solid #0084CA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 32px 0 24px;
}

.total {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    font-size: 22px;
    border-bottom: 1px solid #CECECE;
    margin-top: 110px;
}

.buttons {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin-left: 30px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.checkbox {
    position: relative;
    input {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
    label {
        font-size: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    span {
        min-width: 30px;
        height: 30px;
        border: 2px solid #0084CA;
        display: flex;
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        margin-right: 15px;
        svg {
            visibility: hidden;
            opacity: 0;
            transition: .3s;
        }
    }
    input:checked + label span svg {
        opacity: 1;
        visibility: visible;
    }
}

.inputCount {
    overflow: hidden;
    position: relative;
    font-size: 22px;
    font-weight: 500;
    border-radius: 8px;
    min-width: 133px;
    input {
        border: none;
        color: #080808;
        display: block;
        width: 133px;
        height: 46px;
        border-radius: 8px;
        padding-left: 20px;
        padding-right: 20px;
        border: 2px solid #D9D9D9;
        &:focus {
            outline: none;
            border-color: darken(#D9D9D9, 3%);
        }
    }
}

.plus,
.minus {
    position: absolute;
    right: 0;
    height: 23px;
    width: 30px;
    background-color: #D9D9D9;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    &:hover {
        background-color: darken(#D9D9D9, 3%);
    }
    &:active {
        background-color: darken(#D9D9D9, 7%);
    }
    &::before {
        content: "";
        width: 0; 
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid #0084CA;
    }
}

.plus {
    border-radius: 0 8px 0 0;
    top: 0;
    border-bottom: 1px solid #C5C5C5;
}

.minus {
    bottom: 0;
    border-radius: 0 0 8px 0;
    &::before {
        transform: rotate(-180deg);
    }
}

.code {
    width: 225px;
    height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 50px auto 10px;
    img, svg {
        max-width: 100%;
    }
}

.loader {
    text-align: center;
    margin: 50px auto;
    p {
        margin-top: 15px;
        font-size: 24px;
    }
}

.info {
    text-align: center;
    p {
        font-size: 24px;
        margin-bottom: 45px;
    }
}

.success {
    margin-top: 50px;
}

.successImage {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.errorImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
}

.modalList {
    list-style: none;
    text-align: left;
    max-width: 570px;
    margin: 0 auto;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    span {
        font-weight: 500;
        font-size: 22px;
    }
    b {
        font-weight: 700;
        font-size: 28px;
        color: #0084CA;
    }
    br {
        display: none;
    }
}

@media(max-width: $tabletMaxWidth) {
    .box {
        margin-top: 55px;
    }
    .sumMobileText {
        display: block;
    }

    .sumDesktopText {
        display: none;
    }

    .checkbox {
        span {
            display: flex;
        }
    }

    .plus,
    .minus {
        display: none;
    }

    .inputCount input {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .successBox {
        margin-top: 70px;
    }

    .modalList {
        margin: 30px auto;
    }
}

@media(max-width: $mobileMaxWidth) {
    .title {
        br {
            display: block;
        }
    }
    .sum {
        text-align: center;
        display: block;
        border-top: 1px solid #CECECE;
        padding-top: 12px;
        margin-top: 40px;
        margin-bottom: 65px;
        > span {
            display: block;
        }
        .key {
            margin-top: 10px;
        }
    }

    .option {
        margin-top: 40px;
    }

    .name {
        br {
            display: block;
        }
    }

    .setting {
        flex-direction: column;
        height: auto;
        padding: 15px;
    }

    .checkbox {
        display: inline-flex;
        margin-bottom: 12px;
        label {
            font-size: 18px;
        }
    }

    .total {
        padding: 12px 0;
        border-top: 1px solid #CECECE;
        display: block;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
        span {
            display: block;
        }
        .key {
            font-size: 28px;
            margin-top: 10px;
        }
    }

    .buttons {
        flex-direction: column;
        button {
            margin-left: 0;
            margin-bottom: 30px;
        }
    }

    .successBox {
        margin-top: 30px;
    }

    .errorImage {
        margin-bottom: 25px;
    }

    .modalList {
        span {
            font-size: 16px;
            line-height: 2;
        }
        br {
            display: block;
        }
    }
}