@import "@styles/settings.scss";

.box {
    background: #fff;
    box-shadow: 0 4px 10px rgba(#000, .08);
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    >div {
        width: 33.3333%;
    }
}

.logo {
    display: inline-flex;
    margin: 0 auto;
}

.phone {
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 21px;
    font-weight: 500;
}

.phoneIcon {
    display: none;
}

@media(max-width: $mobileMaxWidth) {
    body {
        padding-top: 70px;
    }

    .box {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .container {
        height: 70px;
    }

    .phoneText {
        display: none;
    }

    .phoneIcon {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 5px;
    }
}